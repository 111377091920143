import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  DialogContentText,
  Box
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

type ContentConfirmationDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (validator_remark: string, notify_users: boolean) => void;
  actionType: 'corrected' | 'noChange'; // New prop to determine the action type
  row?: any; // Optional row data
  isSubmitting: boolean;
};

const ContentConfirmationDialog: React.FC<ContentConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  actionType,
  isSubmitting
}) => {
  const [remark, setRemark] = useState('');
  const [notifyUsers, setNotifyUsers] = useState(false);

  const handleConfirm = () => {
    onConfirm(remark, notifyUsers);
  };

  return (
    <Dialog open={open} onClose={(event, reason) => {
      if (isSubmitting) {
        return;
      }
      if (reason === "backdropClick" || reason === "escapeKeyDown") {
        return;
      }
      onClose(); 
    }}
    BackdropProps={{ style: { pointerEvents: isSubmitting ? 'none' : 'auto' } }}>
      <DialogTitle>
        {actionType === 'corrected' ? 'Mark as Corrected' : 'No Changes Required'}
      </DialogTitle>
      <DialogContent>
      <DialogContentText>
      {actionType === 'corrected' ? "Please enter a remark for the changes done and choose whether to notify users who have attempted this question." 
      : "Please enter remark of the reported question and explanation for no error."}
        </DialogContentText>
        <Stack spacing={2} style={{marginTop:"10px", pointerEvents: isSubmitting ? 'none' : 'auto'}}>
          <TextField
            label={actionType === 'corrected' ? 'Remark on Changes' : 'Explanation for No Changes'}
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            multiline
            rows={4}
            fullWidth
          />
          <Stack>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={notifyUsers}
                onChange={(e) => setNotifyUsers(e.target.checked)}
                style={{color:"#000000"}}
              />
            }
            label="Send notification to all users"
          /> */}
          <FormControlLabel
            control={
              <Checkbox
                checked={notifyUsers}
                onChange={(e) => setNotifyUsers(e.target.checked)}
                 style={{color:"#000000"}}
              />
            }
            label="Send notification to all users who have attempted this question"
          />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button style={{pointerEvents: isSubmitting ? 'none' : 'auto'}} onClick={onClose}>Cancel</Button>
        <Button onClick={handleConfirm} color="primary" variant="contained" style={{backgroundColor: '#000000', pointerEvents: isSubmitting ? 'none' : 'auto'}}>
          {actionType === 'corrected' ? 'Mark as Corrected' : 'Move'}
          {isSubmitting && (
      <CircularProgress
        size={20} // Size of the loader
        style={{ marginLeft: 10, color: 'white' }} // Adjust styling here
      />
    )}

        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContentConfirmationDialog;
