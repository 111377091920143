import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { serverDetails, SidebarContent } from "../../store/slices/constants/staticContents";
import { useAppDispatch, useAppSelecter } from "../../hooks/storeHooks";
import { ObjectValues } from "../../pages/login";
import { setDrawer, setSelectedModule } from "../../store/slices/constants";
import { paragraphs, subHeading } from "../CustomTexts";
import { Box, Stack, Chip } from "@mui/material";
import CustemListItem from "../CustemListItem";
import { useNavigate } from "react-router-dom";
import globalColors from "../../globalColors";
import CustomIconButton from "../CustomIconButton";
import { ArrowRight, arrowLeft } from "../../assets/svgs";
import { columnVerticalCenter, rowCenter, rowVerticalCenter } from "../../GlobalStyles";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  position: "relative",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  position: "relative",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  position: "relative",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  height: "100%",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function CustomSidebar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const reduxState = useAppSelecter((state: ObjectValues) => state.constantSlice);
  const selectedModule = reduxState.selectedModule;

  const open = reduxState.drawerOpen;
  const handleDrawerOpen = () => {
    dispatch(setDrawer(true));
  };

  const handleDrawerClose = () => {
    dispatch(setDrawer(false));
  };

  const getServerEnvironment = () => {
    if (serverDetails.includes("test.netpractice.in") && open) {
      return "Test Database";
    } else if (serverDetails.includes("test.netpractice.in") && !open) {
      return "Test";
    } else if (serverDetails.includes("ng.netpractice.in")  && open) {
      return "Live Database";
    } else if (serverDetails.includes("test.netpractice.in") && !open) {
      return "Live";
    } 
    return "Unknown Environment";
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
    >
      <Stack
        spacing="3px"
        sx={{ p: "15px", justifyContent: "space-between", flex: 8, overflow: "hidden" }}
      >
        <Stack spacing="3px">
          {paragraphs({
            text: open ? SidebarContent.heading : SidebarContent.closeheading,
          })}
          <List sx={{ p: 0 }}>
            {SidebarContent.list.map((list) => {
              const { label, icon, path } = list;
              const isSelected = selectedModule.label === label;
              return (
                <CustemListItem
                  key={`${label}`}
                  keyId={`${label}`}
                  onClick={() => {
                    dispatch(setSelectedModule(list));
                    navigate(path);
                  }}
                  isSelected={isSelected}
                  listItemIconsx={{ mr: open ? "10px" : "auto" }}
                  icon={icon}
                  listItemTextsx={{ opacity: open ? 1 : 0 }}
                  primary={label}
                />
              );
            })}
          </List>
        </Stack>

        <Stack
          spacing="2px"
          direction={open ? "row" : "column"}
          sx={open ? rowVerticalCenter : columnVerticalCenter}
        >
          <Box sx={{ ...columnVerticalCenter, flex: 8, gap: "10px" }}>
            <Chip
              label={getServerEnvironment()}
              color={getServerEnvironment() === "Test Database" || "Test" ? "warning" : "success"}
              size={open ? "small" : "medium"}
              sx={{
                mb: "5px", // Add some margin below the badge
                borderRadius: open ? "16px" : "50%", // Rounded when closed
                minWidth: open ? "auto" : "20px", // Adjust size for closed state
                minHeight: open ? "auto" : "45px", // Adjust height for closed state
                textAlign: "center",
              }}
            />
            <Box
              sx={{
                ...rowCenter,
                flex: 8,
                flexDirection: open ? "row" : "column",
                gap: "3px",
              }}
            >
              {subHeading({
                text: `Version`,
                size: "0.7rem",
                weight: 500,
                sx: {
                  textAlign: "center",
                  whiteSpace: "normal",
                  lineHeight: "0.9rem",
                  color: globalColors.primary.darkGrey,
                },
              })}
              {subHeading({
                text: `${reduxState.version}`,
                size: "0.7rem",
                weight: 600,
                sx: {
                  textAlign: "center",
                  whiteSpace: "normal",
                  lineHeight: "0.9rem",
                  color: globalColors.primary.darkGrey,
                },
              })}
            </Box>
          </Box>
          <CustomIconButton
            sx={{ padding: "5px", width: "fit-content" }}
            content={!open ? ArrowRight : arrowLeft}
            onClick={() => {
              if (open) {
                handleDrawerClose();
              } else {
                handleDrawerOpen();
              }
            }}
          />
        </Stack>
      </Stack>
    </Drawer>
  );
}
