import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import CardHeader from '@mui/material/CardHeader';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

interface questionQualityCheckBoxesProps {
    header: string
}

const QuestionQualityCheckBoxes: React.FC<questionQualityCheckBoxesProps> = ({ header }) => {
    return (
        <Card sx={{ maxWidth: 500 }}>
            {/* <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {header}
                </Typography>
                <Tooltip title="This is a tooltip" arrow>
          <InfoIcon className="tooltip-icon" />
        </Tooltip>
                <Typography variant="body2" color="text.secondary">
                    
                </Typography>
            </CardContent> */}
            <CardHeader
        action={
          <IconButton aria-label="settings">
            <InfoIcon />
          </IconButton>
        }
        title={header}
        titleTypographyProps={{variant:'subtitle1' }}
      />
            <CardActions sx={{
                alignSelf: "stretch",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                // 👇 Edit padding to further adjust position
                p: 1,
            }}>
                <Button size="small">Fail</Button>
                <Button size="small" style ={{backgroundColor:"black", color:"white"}}>Pass</Button>
            </CardActions>
        </Card>
    );
}
export default QuestionQualityCheckBoxes;