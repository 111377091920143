import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { breadcrumbData, initialModule } from "../constants";
import {
  ModuleDetails,
  ModuleDetailsInMockTest,
  allModulesInMockTest,
  allStatus,
  allStatusInMockTestModule,
  allStatusInMockTestModule2,
  breadcrumbContent,
  defaultStructure,
  getNextModule,
  getNextModuleInMockTest,
  structureProps,
} from "../constants/staticContents";
import { store } from "../../store";
import { formatStructureData } from "../../../pages/exams";
import { fetchTableData } from "./thunks/fetchTableData";
import { WritableDraft } from "immer/dist/internal";
import { fetchListData, fetchMockListData } from "./thunks/fetchListData";
import { fetchAllClusters, fetchAllExamsTabData, fetchAllMockQuestionsTabData, fetchAllQuestionsTabData, fetchClusterQuestions, fetchContentData, fetchSinlgQustion, fetchTagsData } from "./thunks/fetchContentData";
import { ObjectValues } from "../../../pages/login";
import { fetchHistory } from "./thunks/fetchHistory";

export type BadgeCounts = {
  [key: string]: number;
};

export type constantsRecord = {
  is_expanded: boolean;
  fetchAgain: ObjectValues;
  breadcrumb: breadcrumbData[];
  tags_list: ObjectValues[],
  cluster_questions: ObjectValues,
  single_questions: ObjectValues,
  exams_structure: structureProps;
  formatted_exams_structure: structureProps[];
  docked_exams: structureProps;
  docked_exams_table: structureProps;
  content_list: {
    "id": number,
    "model": string
  }[];
  history_list: ObjectValues;
  globalLoad: boolean; all_clusters: ObjectValues[],
  all_exam_status_data: BadgeCounts,
  all_goal_status_data: BadgeCounts,
  all_clusters_status_data: BadgeCounts
  all_questions_status_data: BadgeCounts,
  all_mockTestSeries_status_data: BadgeCounts,
  all_mockTests_status_data: BadgeCounts,
  paginationModel: {
    page: number,
    pageSize: number
  }
};
export const formatStructures = (
  exams_structure: structureProps
): structureProps[] => {
  const allDetails: structureProps[] = [];

  const processStructure = (currentStructure: structureProps) => {
    allDetails.push(currentStructure);

    if (currentStructure.child) {
      processStructure(currentStructure.child);
    }
  };

  processStructure(exams_structure);

  return allDetails;
};

const breadFunct = (
  formatted_exams_structure: structureProps[],
  payload: structureProps
) => {
  const breadCrumb: any = [];
  formatted_exams_structure.map((item: structureProps, i: number) => {
    const nextVal = formatted_exams_structure[i + 1] ?? null;
    const nextNextVal = formatted_exams_structure[i + 2] ?? null;
    const preVal = formatted_exams_structure[i - 1] ?? null;
    const isDisable = nextVal?.create || nextNextVal ? false : nextVal ? true : false
    const onClick = () => {
      store.dispatch(
        setExamStructure(
          formatStructureData(
            formatStructureData(
              payload,
              {
                create: false,
                edit: false,
                selected_row_field: "",
                selected_row: null,
                child: null,
                dockedList: false,
                dockedTable: true,
                fetchAgain: nextVal ? nextVal.create ?? false : item.create ? true : false,
                loading: nextVal ? nextVal.create ?? false : item.create ? true : false
              },
              nextVal ? nextVal.title : item.title
            ),
            {
              create: false,
              edit: false,
              dockedList: true,
              dockedTable: false,
            },
            nextVal ? item?.title : preVal?.title
          )

        )
      );
    }
    const structreBread = breadcrumbContent(item.title, item.selected_row);
    const title = item.selected_row
      ? item.selected_row[item.selected_row_field ?? "name"]
      : structreBread.list;
    if (!item.edit && item.selected_row && i === 0) {
      breadCrumb.push({
        icon: initialModule.icon,
        onClick: () => {
          store.dispatch(
            setExamStructure(formatStructureData(
              payload,
              {
                create: false,
                edit: false,
                selected_row_field: "",
                selected_row: null,
                child: null,
                dockedList: true,
                dockedTable: false,
              },
              item.title
            )
            )
          );
        }
      });
    }
    if (i === 0) {
      breadCrumb.push({
        label: title,
        onClick: onClick,
        disabled: isDisable,
      });
    }
    if (item.create) {
      breadCrumb.push({ label: structreBread.create });
    } else if (item.edit) {
      breadCrumb.push({ label: structreBread.edit });
    } else if (i !== 0) {
      breadCrumb.push({
        label: title,
        disabled: isDisable,
        onClick: onClick,
      });
    }
    return item;
  });
  return breadCrumb;
};
const initialConstantsRecord: constantsRecord = {
  breadcrumb: breadFunct(formatStructures(defaultStructure), defaultStructure),
  is_expanded: false, content_list: [],
  fetchAgain: {},
  cluster_questions: {},
  single_questions: {}, all_clusters: [],
  globalLoad: true,
  exams_structure: defaultStructure,
  formatted_exams_structure: formatStructures(defaultStructure),
  history_list: {}, tags_list: [],
  docked_exams:
    formatStructures(defaultStructure).find(
      (it: structureProps) => it.dockedList
    ) ?? defaultStructure,
  docked_exams_table:
    formatStructures(defaultStructure).find(
      (it: structureProps) => it.dockedTable
    ) ?? defaultStructure,
  all_exam_status_data: {
    ready: 0,
    draft: 0,
    published: 0,
    archived: 0,
    unpublished: 0,
    approved: 0,
    rejected: 0
  },
  all_goal_status_data: {
    ready: 0,
    draft: 0,
    published: 0,
    archived: 0,
    unpublished: 0,
    approved: 0,
    rejected: 0
  },
  all_clusters_status_data: {
    ready: 0,
    draft: 0,
    published: 0,
    archived: 0,
    unpublished: 0,
    approved: 0,
    rejected: 0
  },
  all_mockTestSeries_status_data: {
    ready: 0,
    draft: 0,
    published: 0,
    released: 0,
    archived: 0,
    unpublished: 0,
    approved: 0,
    rejected: 0
  },
  all_mockTests_status_data: {
    ready: 0,
    draft: 0,
    published: 0,
    released: 0,
    archived: 0,
    unpublished: 0,
    approved: 0,
    rejected: 0
  },
  all_questions_status_data: {
    ready: 0,
    draft: 0,
    published: 0,
    archived: 0,
    unpublished: 0,
    approved: 0,
    rejected: 0
  },
  paginationModel: {
    page: 0,
    pageSize: 10
  }
};
const setExams = (
  state: WritableDraft<constantsRecord>,
  action: { payload: any; type?: string }
) => {
  state.exams_structure = action.payload;
  state.formatted_exams_structure = formatStructures(action.payload);
  state.docked_exams =
    formatStructures(action.payload).find(
      (it: structureProps) => it.dockedList
    ) ?? action.payload;
  state.docked_exams_table =
    formatStructures(action.payload).find(
      (it: structureProps) => it.dockedTable
    ) ?? action.payload;
  state.breadcrumb = breadFunct(
    state.formatted_exams_structure,
    action.payload
  );
};
const exams = createSlice({
  name: "exams",
  initialState: initialConstantsRecord,
  reducers: {
    setExpand: (state) => {
      state.is_expanded = !state.is_expanded;
    },
    setExamStructure: (state, action: PayloadAction<structureProps>) => {
      console.log(action)
      setExams(state, action);
    },
    setFetchAgain: (state, action: PayloadAction<ObjectValues>) => {
      state.fetchAgain = action.payload
    },
    setPaginationModel: (state, action: PayloadAction<{ page: number, pageSize: number }>) => {
      state.paginationModel = action.payload
      state.docked_exams_table = { ...state.docked_exams_table, fetchAgain: true }
    }
  },
  extraReducers(builder) {
    // verify api call started
    builder.addCase(fetchTableData.pending, function (state, action) {
      // state.loggedUser = null
    });
    // fetchAllClusters
    builder.addCase(fetchAllClusters.rejected, function (state, action) {
      // state.loggedUser = null
    });

    // verify api call started
    builder.addCase(fetchAllClusters.pending, function (state, action) {
      // state.loggedUser = null
    });
    // verify api call success
    builder.addCase(
      fetchAllClusters.fulfilled,
      function (state, action: PayloadAction<any>) {
        state.all_clusters = action.payload

      });


    // verify api call success
    builder.addCase(
      fetchTableData.fulfilled,
      function (state, action: PayloadAction<any>) {
        setExams(state, {
          payload: formatStructureData(
            state.exams_structure,
            { list: action.payload && action.payload.results ? action.payload.results : action.payload ?? [], fetchAgain: false, loading: false },
            state.docked_exams_table.title
          ),
        });
        state.globalLoad = false
        if (action.payload && action.payload[0]?.module?.includes("goals")) {
          state.all_goal_status_data = {
            ready: action.payload?.filter((a: any) => a.status === 'ready')?.length || 0,
            approved: action.payload?.filter((a: any) => a.status === 'approved')?.length || 0,
            draft: action.payload?.filter((a: any) => a.status === 'draft')?.length || 0,
            published: action.payload?.filter((a: any) => a.status === 'published')?.length || 0,
            archived: action.payload?.filter((a: any) => a.status === 'archived')?.length || 0,
            rejected: action.payload?.filter((a: any) => a.status === 'rejected')?.length || 0,
            unpublished: action.payload?.filter((a: any) => a.status === 'unpublished')?.length || 0
          }
        } else if (action.payload && action.payload[0]?.module?.includes("clusters")) {
          const clusterPayload = action.payload.map((c: any) => c.cluster)
          state.all_clusters_status_data = {
            ready: clusterPayload?.filter((a: any) => a.status === 'ready')?.length || 0,
            approved: clusterPayload?.filter((a: any) => a.status === 'approved')?.length || 0,
            draft: clusterPayload?.filter((a: any) => a.status === 'draft')?.length || 0,
            published: clusterPayload?.filter((a: any) => a.status === 'published')?.length || 0,
            archived: clusterPayload?.filter((a: any) => a.status === 'archived')?.length || 0,
            rejected: clusterPayload?.filter((a: any) => a.status === 'rejected')?.length || 0,
            unpublished: clusterPayload?.filter((a: any) => a.status === 'unpublished')?.length || 0
          }
        } else if (action.payload && action.payload[0]?.module?.includes("mock Test Series")) {
          state.all_mockTestSeries_status_data = {
            ready: action.payload?.filter((a: any) => a.status === 'ready')?.length || 0,
            approved: action.payload?.filter((a: any) => a.status === 'approved')?.length || 0,
            draft: action.payload?.filter((a: any) => a.status === 'draft')?.length || 0,
            published: action.payload?.filter((a: any) => a.status === 'published')?.length || 0,
            released: action.payload?.filter((a: any) => a.status === 'released')?.length || 0,
            archived: action.payload?.filter((a: any) => a.status === 'archived')?.length || 0,
            rejected: action.payload?.filter((a: any) => a.status === 'rejected')?.length || 0,
            unpublished: action.payload?.filter((a: any) => a.status === 'unpublished')?.length || 0
          }
        } else if (action.payload && action.payload[0]?.module?.includes("mock Tests")) {
          console.log("action.payload", action.payload)
          state.all_mockTests_status_data = {
            ready: action.payload?.filter((a: any) => a.status === 'ready')?.length || 0,
            approved: action.payload?.filter((a: any) => a.status === 'approved')?.length || 0,
            draft: action.payload?.filter((a: any) => a.status === 'draft')?.length || 0,
            published: action.payload?.filter((a: any) => a.status === 'published')?.length || 0,
            released: action.payload?.filter((a: any) => a.status === 'released')?.length || 0,
            archived: action.payload?.filter((a: any) => a.status === 'archived')?.length || 0,
            rejected: action.payload?.filter((a: any) => a.status === 'rejected')?.length || 0,
            unpublished: action.payload?.filter((a: any) => a.status === 'unpublished')?.length || 0
          }
        }
        //   else {
        //     state.all_mockTests_status_data = state.all_mockTestSeries_status_data = state.all_clusters_status_data = state.all_goal_status_data = {
        //       ready: 0,
        //       approved:  0,
        //       draft: 0,
        //       published: 0,
        //       released: 0,
        //       archived: 0,
        //       rejected:  0,
        //       unpublished: 0
        //   }
        // }
      }
    );

    // verify api call rejected
    builder.addCase(fetchTableData.rejected, function (state, action) {
      // state.loggedUser = null
    });

    // question
    builder.addCase(fetchAllMockQuestionsTabData.fulfilled, function (state, action: PayloadAction<any>) {
      state.all_questions_status_data = {
        ready: action.payload?.find((a: any) => a.status === 'ready')?.count || 0,
        approved: action.payload?.find((a: any) => a.status === 'approved')?.count || 0,
        draft: action.payload?.find((a: any) => a.status === 'draft')?.count || 0,
        published: action.payload?.find((a: any) => a.status === 'published')?.count || 0,
        archived: action.payload?.find((a: any) => a.status === 'archived')?.count || 0,
        rejected: action.payload?.find((a: any) => a.status === 'rejected')?.count || 0,
        unpublished: action.payload?.find((a: any) => a.status === 'unpublished')?.count || 0
      }
      console.log(state.all_questions_status_data)
    });

    builder.addCase(fetchAllQuestionsTabData.fulfilled, function (state, action: PayloadAction<any>) {
      state.all_questions_status_data = {
        ready: action.payload?.results?.filter((a: any) => a.status === 'ready')?.length || 0,
        approved: action.payload?.results?.filter((a: any) => a.status === 'approved')?.length || 0,
        draft: action.payload?.results?.filter((a: any) => a.status === 'draft')?.length || 0,
        published: action.payload?.results?.filter((a: any) => a.status === 'published')?.length || 0,
        archived: action.payload?.results?.filter((a: any) => a.status === 'archived')?.length || 0,
        rejected: action.payload?.results?.filter((a: any) => a.status === 'rejected')?.length || 0,
        unpublished: action.payload?.results?.filter((a: any) => a.status === 'unpublished')?.length || 0
      }
      console.log(state.all_questions_status_data)
    });

    // verify api call started
    builder.addCase(fetchListData.pending, function (state, action) {
      // state.loggedUser = null
    });
    // verify api call success
    builder.addCase(
      fetchListData.fulfilled,
      function (state, action: PayloadAction<any>) {
        const list = action.payload.list ?? [];
        console.log("check", action.payload)
        const structureToUpdate = action.payload.structure;

        const seelectedRow =
          action.payload.list?.length > 0 ? action.payload.list[0] : null;
        function updateListRecursively(node: structureProps) {
          // Update the list property to an empty array
          const updatedNode = {
            ...node, list: [],
            selected_row: null,
          };
          // If the node has children, recursively update their lists
          if (updatedNode.child) {
            updatedNode.child = updateListRecursively(updatedNode.child);
          }

          return updatedNode;
        }
        const filterListLocally = (list: ObjectValues[]) => {
          return list && list.length > 0 ? ModuleDetails[structureToUpdate.title]?.arrange_path
            ? ModuleDetails[structureToUpdate.title]
              .formatList(list)
              .filter((s) =>
                ModuleDetails[structureToUpdate.title]?.isTab
                  ? (s.cluster?.status ?? s.status) ===
                  structureToUpdate.selectedTab?.label.toLowerCase()
                  : true
              )
              .filter((it) => it.is_active)
            : ModuleDetails[structureToUpdate.title]?.formatList(list)
              .filter((it) => it.is_active) : []
        }
        const child =
          filterListLocally(list)?.length > 0 && structureToUpdate?.child
            ? { ...structureToUpdate.child, fetchAgain: true, loading: true }
            : structureToUpdate?.child
              ? updateListRecursively(structureToUpdate.child)
              : seelectedRow
                ? {
                  fetchAgain: true,
                  title: getNextModule(structureToUpdate.title),
                  list: [],
                  child: null,
                  parentDetails: seelectedRow,
                  loading: true,
                  create: false,
                  edit: false,
                  selected_row: null,
                  dockedTable: true,
                  dockedList: false,
                  selectedTab: allStatus[0],
                }
                : null;
        setExams(state, {
          payload: formatStructureData(
            state.exams_structure,
            {
              list: list,
              fetchAgain: false,
              loading: false,
              selected_row: seelectedRow,
              child: child,
            },
            structureToUpdate?.title
          ),
        });
        // const { payload } = action;
        // state.loggedUser = payload;
      }
    );

    // verify api call rejected
    builder.addCase(fetchListData.rejected, function (state, action) {
      // state.loggedUser = null
    });

    builder.addCase(
      fetchMockListData.fulfilled,
      function (state, action: PayloadAction<any>) {
        const list = action.payload.list ?? [];
        const structureToUpdate = action.payload.structure;

        const seelectedRow =
          action.payload.list?.length > 0 ? action.payload.list[0] : null;
        function updateListRecursively(node: structureProps) {
          // Update the list property to an empty array
          const updatedNode = {
            ...node, list: [],
            selected_row: null,
          };
          // If the node has children, recursively update their lists
          if (updatedNode.child) {
            updatedNode.child = updateListRecursively(updatedNode.child);
          }

          return updatedNode;
        }
        const filterListLocally = (list: ObjectValues[]) => {
          return list && list.length > 0 ? ModuleDetailsInMockTest[structureToUpdate.title]?.arrange_path
            ? ModuleDetailsInMockTest[structureToUpdate.title]
              .formatList(list)
              .filter((s) =>
                ModuleDetailsInMockTest[structureToUpdate.title]?.isTab
                  ? (s.cluster?.status ?? s.status) ===
                  structureToUpdate.selectedTab?.label.toLowerCase()
                  : true
              )
              .filter((it) => it.is_active)
            : ModuleDetailsInMockTest[structureToUpdate.title]?.formatList(list)
              .filter((it) => it.is_active) : []
        }
        const child =
          filterListLocally(list)?.length > 0 && structureToUpdate?.child
            ? { ...structureToUpdate.child, fetchAgain: true, loading: true }
            : structureToUpdate?.child
              ? updateListRecursively(structureToUpdate.child)
              : seelectedRow
                ? {
                  fetchAgain: true,
                  title: getNextModuleInMockTest(structureToUpdate.title),
                  list: [],
                  child: null,
                  parentDetails: seelectedRow,
                  loading: true,
                  create: false,
                  edit: false,
                  selected_row: null,
                  dockedTable: true,
                  dockedList: false,
                  selectedTab: allStatus[0],
                  selectedTabInMockTest: allStatusInMockTestModule[0],
                  selectedTabInMockTest2: allStatusInMockTestModule2[0],
                  selectedTabInMockTest3: allStatus[0]
                }
                : null;
        setExams(state, {
          payload: formatStructureData(
            state.exams_structure,
            {
              list: list,
              fetchAgain: false,
              loading: false,
              selected_row: seelectedRow,
              child: structureToUpdate?.title == "question" ? null : child,
            },
            structureToUpdate?.title
          ),
        });
        // const { payload } = action;
        // state.loggedUser = payload;
      }
    );

    builder.addCase(fetchContentData.pending, function (state, action) {
      state.content_list = []
    });

    builder.addCase(fetchContentData.fulfilled, function (state, action: PayloadAction<any>) {
      state.content_list = action.payload ?? []
    });
    builder.addCase(fetchContentData.rejected, function (state, action) {
      state.content_list = []
    });
    //hirtsor
    builder.addCase(fetchHistory.pending, function (state, action) {
      state.history_list = {}
    });
    builder.addCase(fetchHistory.fulfilled, function (state, action: PayloadAction<any>) {
      state.history_list = { ...state.history_list, ...(action.payload ?? {}) }
    });
    builder.addCase(fetchHistory.rejected, function (state, action) {
      state.history_list = {}
    });
    builder.addCase(fetchTagsData.fulfilled, function (state, action) {
      state.tags_list = action.payload ?? []
    });
    builder.addCase(fetchClusterQuestions.fulfilled, function (state, action) {
      state.cluster_questions = { ...state.cluster_questions, ...action.payload }
    });
    builder.addCase(fetchSinlgQustion.fulfilled, function (state, action) {
      state.single_questions = { ...state.single_questions, ...action.payload }
    });
    builder.addCase(fetchAllExamsTabData.fulfilled, function (state, action: PayloadAction<any>) {
      state.all_exam_status_data = {
        ready: action.payload?.filter((a: any) => a.status === 'ready')?.length || 0,
        approved: action.payload?.filter((a: any) => a.status === 'approved')?.length || 0,
        draft: action.payload?.filter((a: any) => a.status === 'draft')?.length || 0,
        published: action.payload?.filter((a: any) => a.status === 'published')?.length || 0,
        archived: action.payload?.filter((a: any) => a.status === 'archived')?.length || 0,
        rejected: action.payload?.filter((a: any) => a.status === 'rejected')?.length || 0,
        unpublished: action.payload?.filter((a: any) => a.status === 'unpublished')?.length || 0
      }
    });
  },
});
export const { setExpand, setExamStructure, setFetchAgain, setPaginationModel } = exams.actions;
export default exams.reducer;
